import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { richText } from '../../../modules/rich-text';
import { getParagraphIds } from '../../../modules/paragraphs';

import PrivacyStyles from './privacy.module.scss';

const Privacy = () => {
    const intl = useIntl();
    const sections = getParagraphIds(intl.messages, 'pages.privacy.sections', 'headline', 'copytext');

    return (
        <div className={PrivacyStyles.privacy}>
            {sections.map((section, sectionIndex) => {
                return (
                    <div key={sectionIndex} className={PrivacyStyles.section}>
                        {intl.messages[section.headline] && (
                            <h6 className="color-white">
                                <FormattedMessage id={section.headline} />
                            </h6>
                        )}

                        {sections[sectionIndex].copytext.map(
                            (c: string, copytextIndex: string | number | undefined) => {
                                return (
                                    <p key={copytextIndex} className="color-white">
                                        {richText(c)}
                                    </p>
                                );
                            },
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Privacy;
